import { useTheme } from '@/stores/theme';

import { cn } from '@/lib/utils';
import { LogoDark, LogoLight } from '@/components/custom-icons/Logo';
import { WebApp } from '@grammyjs/web-app';
const Loading = () => {
    const { theme } = useTheme();
    const actualTheme =
        WebApp.initData.length === 0 ? theme : WebApp.colorScheme;
    return (
        <div
            className={cn(
                'min-w-screen flex min-h-[100dvh] items-center justify-center',
                actualTheme === 'dark' ? 'bg-[#101828]' : 'bg-white'
            )}
        >
            <div className="h-[120px] w-[120px] scale-150">
                {actualTheme === 'dark' ? <LogoDark /> : <LogoLight />}
            </div>
        </div>
    );
};

export default Loading;
