import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // load translation using http
    .use(Backend)
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            lookupQuerystring: 'lang'
        },
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;

export const langs = {
    en: {
        nativeName: 'English'
    },
    ru: {
        nativeName: 'Русский'
    },
    fa: {
        nativeName: 'پارسی'
    }
};

export type LangTypes = 'en' | 'ru' | 'fa';
