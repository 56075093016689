export default {
    tonConnectManifest: 'https://mytonswap.com/wallet/manifest.json',
    endpoint: 'https://app.mytonswap.com/api'
};

export const TON_FEE_MINIMUM = 0.25;
export const TON_ADDR = 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c';
export const STON_ROUTER_V1 =
    'EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt';
export const PTON_V1 = 'EQCM3B12QK1e4yZSf8GtBRT0aLMNyEsBc_DhVfRRtOEffLez';
export const PTON_V2 = 'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S';
